// src/components/Login.js
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './styles/Login.css';
import logo from '../img/logo2.png'; 

const Login = () => {
  const [identifier, setIdentifier] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post('https://api.sortirleskids.fr/api/login', {
        identifier,
        password
      });
      
      localStorage.setItem('token', response.data.token);

      if (response.data.user.role === 'admin') {
        navigate('/admin/establishment-choice');
      } else {
        setError('Accès refusé : vous devez être administrateur');
      }
    } catch (err) {
      setError('Identifiant ou mot de passe incorrect');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
	  <img src={logo} alt="Logo" className="logo" />
      <div className="login-form">
        <h2>Connexion Administrateur</h2>
        {error && <p className="error-text">{error}</p>}
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <label>Identifiant ou Email:</label>
            <input
              type="text"
              value={identifier}
              onChange={(e) => setIdentifier(e.target.value)}
              placeholder="Email ou Pseudo"
              required
            />
          </div>
          <div className="input-group">
            <label>Mot de passe:</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Mot de passe"
              required
            />
          </div>
          <button type="submit" className="submit-button" disabled={loading}>
            {loading ? 'Connexion...' : 'Se connecter'}
          </button>
        </form>
        <button className="cancel-button" onClick={() => navigate('/admin')}>
          Annuler
        </button>
        <button className="forgot-password-button" onClick={() => navigate('/admin/forgot-password')}>
          Mot de passe oublié ?
        </button>
      </div>
    </div>
  );
};

export default Login;
