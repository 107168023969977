// Liste des départements français avec leurs coordonnées pour le zoom
export const departments = {
    '01 - Ain': { latitude: 46.0805, longitude: 5.4052, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '02 - Aisne': { latitude: 49.5628, longitude: 3.6245, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '03 - Allier': { latitude: 46.3392, longitude: 3.2636, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '04 - Alpes-de-Haute-Provence': { latitude: 44.0922, longitude: 6.2356, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '05 - Hautes-Alpes': { latitude: 44.6625, longitude: 6.3052, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '06 - Alpes-Maritimes': { latitude: 43.7102, longitude: 7.2620, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '07 - Ardèche': { latitude: 44.7353, longitude: 4.5986, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '08 - Ardennes': { latitude: 49.7686, longitude: 4.7200, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '09 - Ariège': { latitude: 42.9455, longitude: 1.4066, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '10 - Aube': { latitude: 48.2999, longitude: 4.0817, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '11 - Aude': { latitude: 43.1836, longitude: 2.3522, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '12 - Aveyron': { latitude: 44.3494, longitude: 2.5737, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '13 - Bouches-du-Rhône': { latitude: 43.2965, longitude: 5.3698, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '14 - Calvados': { latitude: 49.1829, longitude: -0.3707, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '15 - Cantal': { latitude: 45.0542, longitude: 2.7387, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '16 - Charente': { latitude: 45.6484, longitude: 0.1562, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '17 - Charente-Maritime': { latitude: 45.7467, longitude: -0.6337, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '18 - Cher': { latitude: 47.0837, longitude: 2.3951, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '19 - Corrèze': { latitude: 45.3668, longitude: 1.8636, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '20 - Corse': { latitude: 42.0396, longitude: 9.0129, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    "21 - Côte-d'Or": { latitude: 47.3220, longitude: 5.0415, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    "22 - Côtes-d'Armor": { latitude: 48.5142, longitude: -2.7608, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '23 - Creuse': { latitude: 46.1696, longitude: 2.0455, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '24 - Dordogne': { latitude: 45.1504, longitude: 0.7183, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '25 - Doubs': { latitude: 47.1307, longitude: 6.2341, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '26 - Drôme': { latitude: 44.5569, longitude: 5.0590, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '27 - Eure': { latitude: 49.0912, longitude: 1.1618, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '28 - Eure-et-Loir': { latitude: 48.4469, longitude: 1.4893, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '29 - Finistère': { latitude: 48.2020, longitude: -4.0978, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '30 - Gard': { latitude: 43.9424, longitude: 4.4191, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '31 - Haute-Garonne': { latitude: 43.6043, longitude: 1.4419, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '32 - Gers': { latitude: 43.6468, longitude: 0.5877, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '33 - Gironde': { latitude: 44.8378, longitude: -0.5792, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '34 - Hérault': { latitude: 43.6108, longitude: 3.8767, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '35 - Ille-et-Vilaine': { latitude: 48.1173, longitude: -1.6778, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '36 - Indre': { latitude: 46.8115, longitude: 1.6888, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '37 - Indre-et-Loire': { latitude: 47.3900, longitude: 0.6882, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '38 - Isère': { latitude: 45.1885, longitude: 5.7245, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '39 - Jura': { latitude: 46.7294, longitude: 5.7196, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '40 - Landes': { latitude: 44.0005, longitude: -0.9891, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '41 - Loir-et-Cher': { latitude: 47.5748, longitude: 1.3299, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '42 - Loire': { latitude: 45.7474, longitude: 4.0701, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '43 - Haute-Loire': { latitude: 45.0342, longitude: 3.8857, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '44 - Loire-Atlantique': { latitude: 47.2173, longitude: -1.5534, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '45 - Loiret': { latitude: 47.9025, longitude: 2.2329, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '46 - Lot': { latitude: 44.6104, longitude: 1.6640, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '47 - Lot-et-Garonne': { latitude: 44.3990, longitude: 0.7046, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '48 - Lozère': { latitude: 44.5189, longitude: 3.5004, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '49 - Maine-et-Loire': { latitude: 47.4738, longitude: -0.5470, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '50 - Manche': { latitude: 49.1140, longitude: -1.3384, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '51 - Marne': { latitude: 49.0458, longitude: 4.2411, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '52 - Haute-Marne': { latitude: 48.1120, longitude: 5.1374, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '53 - Mayenne': { latitude: 48.3041, longitude: -0.6174, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '54 - Meurthe-et-Moselle': { latitude: 48.6936, longitude: 6.1846, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '55 - Meuse': { latitude: 49.0763, longitude: 5.3688, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '56 - Morbihan': { latitude: 47.6582, longitude: -2.7608, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '57 - Moselle': { latitude: 49.1193, longitude: 6.1727, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '58 - Nièvre': { latitude: 47.1631, longitude: 3.5244, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '59 - Nord': { latitude: 50.3477, longitude: 3.2621, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '60 - Oise': { latitude: 49.3845, longitude: 2.4137, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '61 - Orne': { latitude: 48.6095, longitude: 0.2923, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '62 - Pas-de-Calais': { latitude: 50.4892, longitude: 2.3958, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '63 - Puy-de-Dôme': { latitude: 45.7750, longitude: 3.0863, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '64 - Pyrénées-Atlantiques': { latitude: 43.2951, longitude: -0.3707, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '65 - Hautes-Pyrénées': { latitude: 43.2329, longitude: 0.0747, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '66 - Pyrénées-Orientales': { latitude: 42.6887, longitude: 2.8948, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '67 - Bas-Rhin': { latitude: 48.5793, longitude: 7.7418, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '68 - Haut-Rhin': { latitude: 48.0785, longitude: 7.3585, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '69 - Rhône': { latitude: 45.7589, longitude: 4.8414, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '70 - Haute-Saône': { latitude: 47.6204, longitude: 6.1570, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '71 - Saône-et-Loire': { latitude: 46.5822, longitude: 4.3867, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '72 - Sarthe': { latitude: 47.8776, longitude: 0.2702, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '73 - Savoie': { latitude: 45.4136, longitude: 6.6349, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '74 - Haute-Savoie': { latitude: 46.0707, longitude: 6.3953, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '75 - Paris': { latitude: 48.8566, longitude: 2.3522, latitudeDelta: 0.1, longitudeDelta: 0.1 },
    '76 - Seine-Maritime': { latitude: 49.4431, longitude: 1.0993, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '77 - Seine-et-Marne': { latitude: 48.5476, longitude: 2.6218, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '78 - Yvelines': { latitude: 48.8143, longitude: 1.9840, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '79 - Deux-Sèvres': { latitude: 46.4167, longitude: -0.3946, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '80 - Somme': { latitude: 49.8951, longitude: 2.3024, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '81 - Tarn': { latitude: 43.7844, longitude: 1.7481, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '82 - Tarn-et-Garonne': { latitude: 44.0833, longitude: 1.4167, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '83 - Var': { latitude: 43.4292, longitude: 6.7353, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '84 - Vaucluse': { latitude: 43.9480, longitude: 4.8083, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '85 - Vendée': { latitude: 46.6694, longitude: -1.4260, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '86 - Vienne': { latitude: 46.5802, longitude: 0.3350, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '87 - Haute-Vienne': { latitude: 45.8336, longitude: 1.2611, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '88 - Vosges': { latitude: 48.1736, longitude: 6.4510, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '89 - Yonne': { latitude: 47.7994, longitude: 3.5667, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '90 - Territoire de Belfort': { latitude: 47.6397, longitude: 6.8633, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '91 - Essonne': { latitude: 48.5383, longitude: 2.4397, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '92 - Hauts-de-Seine': { latitude: 48.8285, longitude: 2.2242, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '93 - Seine-Saint-Denis': { latitude: 48.9133, longitude: 2.4844, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '94 - Val-de-Marne': { latitude: 48.7770, longitude: 2.4878, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '95 - Val-d\'Oise': { latitude: 49.0389, longitude: 2.0393, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '971 - Guadeloupe': { latitude: 16.2650, longitude: -61.5510, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '972 - Martinique': { latitude: 14.6415, longitude: -61.0242, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '973 - Guyane': { latitude: 4.0000, longitude: -53.0000, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '974 - La Réunion': { latitude: -21.1151, longitude: 55.5364, latitudeDelta: 0.5, longitudeDelta: 0.5 },
    '976 - Mayotte': { latitude: -12.8275, longitude: 45.1662, latitudeDelta: 0.5, longitudeDelta: 0.5 },
  };