import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const API_URL = 'https://api.sortirleskids.fr/api';

const HoursEntryForm = () => {
  const navigate = useNavigate();
  const [entityId, setEntityId] = useState('');
  const [entityType, setEntityType] = useState('activity');
  const [regularHours, setRegularHours] = useState({
    lundi: '',
    mardi: '',
    mercredi: '',
    jeudi: '',
    vendredi: '',
    samedi: '',
    dimanche: ''
  });
  const [seasonHours, setSeasonHours] = useState({});
  const [holidayHours, setHolidayHours] = useState({
    lundi: '',
    mardi: '',
    mercredi: '',
    jeudi: '',
    vendredi: '',
    samedi: '',
    dimanche: ''
  });
  const [seasons, setSeasons] = useState([]);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    fetchSeasons();
  }, []);

  const fetchSeasons = async () => {
    try {
      const response = await axios.get(`${API_URL}/seasons`);
      setSeasons(response.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des saisons:', error);
    }
  };

  const handleHoursChange = (day, value, setHours) => {
    setHours((prev) => ({
      ...prev,
      [day]: value
    }));
  };

  const handleSeasonHoursChange = (seasonId, day, value) => {
    setSeasonHours((prev) => ({
      ...prev,
      [seasonId]: {
        ...prev[seasonId],
        [day]: value
      }
    }));
  };

  const translateDayToEnglish = (day) => {
	const dayTranslations = {
	  lundi: 'monday',
	  mardi: 'tuesday',
	  mercredi: 'wednesday',
	  jeudi: 'thursday',
	  vendredi: 'friday',
	  samedi: 'saturday',
	  dimanche: 'sunday'
	};
  
	return dayTranslations[day] || day;
  };  

  const cleanSchedule = (hours) => {
    const cleaned = {};
    Object.entries(hours).forEach(([day, time]) => {
      if (time) {
		const translatedDay = translateDayToEnglish(day);
        cleaned[translatedDay] = time;
      }
    });
    return cleaned;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!entityId) {
      setError('Veuillez entrer un ID valide.');
      return;
    }

    const cleanedRegularHours = cleanSchedule(regularHours);
    const cleanedHolidayHours = cleanSchedule(holidayHours);

    try {
      const scheduleJson = {
        regular: cleanedRegularHours,
        seasons: {},
        vacances_scolaires: cleanedHolidayHours
      };

      // Ajout des horaires de saisons sans les dates
      seasons.forEach((season) => {
        if (seasonHours[season.id]) {
          const cleanedSeasonHours = cleanSchedule(seasonHours[season.id]);
          if (Object.keys(cleanedSeasonHours).length > 0) {
            scheduleJson.seasons[season.name.toLowerCase()] = {
              hours: cleanedSeasonHours
            };
          }
        }
      });

	  if (Object.keys(scheduleJson.seasons).length === 0) {
		delete scheduleJson.seasons;
	  }
	  
		  // Supprimer les vacances_scolaires si elles sont vides
	  if (!scheduleJson.vacances_scolaires) {
		delete scheduleJson.vacances_scolaires;
	  }

      // Envoyer les données au backend
      await axios.post(`${API_URL}/save-schedule`, {
        id: entityId,
        type: entityType,
        scheduleJson
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

	  alert('Horaires enregistrés avec succès.');
      navigate('/admin/establishment-choice');
    } catch (error) {
      console.error('Erreur lors de l\'enregistrement des horaires:', error);

      // Ajout d'un log détaillé de l'erreur
      if (error.response) {
        console.error('Erreur backend:', error.response.data);
        setError(`Erreur backend: ${error.response.data.message || 'Une erreur est survenue'}`);
      } else {
        setError('Une erreur est survenue lors de l\'enregistrement des horaires.');
      }
    }
  };

  const frenchDays = ['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi', 'dimanche'];

  return (
    <div className="form-container">
      <h2>Enregistrer des Horaires</h2>
      {error && <p className="error-text">{error}</p>}
      {success && <p className="success-text">{success}</p>}

      <form onSubmit={handleSubmit}>
        <label>ID de l'entité</label>
        <input
          type="text"
          value={entityId}
          onChange={(e) => setEntityId(e.target.value)}
          placeholder="Entrez l'ID de l'entité"
          required
        />

        <label>Type d'entité</label>
        <select value={entityType} onChange={(e) => setEntityType(e.target.value)}>
          <option value="activity">Activité</option>
          <option value="restaurant">Restaurant</option>
          <option value="family_event">Événement famille</option>
        </select>

        <h3>Horaires réguliers</h3>
        {frenchDays.map((day) => (
          <div key={day}>
            <label>{day.charAt(0).toUpperCase() + day.slice(1)}</label>
            <input
              type="text"
              value={regularHours[day]}
              onChange={(e) => handleHoursChange(day, e.target.value, setRegularHours)}
              placeholder="Ex: 08:00-12:00, 14:00-18:00"
            />
          </div>
        ))}

        <h3>Horaires pour les Saisons</h3>
        {seasons.map(season => (
          <div key={season.id}>
            <h4>{season.name}</h4>
            {frenchDays.map((day) => (
              <div key={`${season.id}-${day}`}>
                <label>{day.charAt(0).toUpperCase() + day.slice(1)}</label>
                <input
                  type="text"
                  value={seasonHours[season.id]?.[day] || ''}
                  onChange={(e) => handleSeasonHoursChange(season.id, day, e.target.value)}
                  placeholder={`Horaires pour ${season.name}`}
                />
              </div>
            ))}
          </div>
        ))}

        <h3>Horaires pour les Vacances Scolaires</h3>
        {frenchDays.map((day) => (
          <div key={day}>
            <label>{day.charAt(0).toUpperCase() + day.slice(1)}</label>
            <input
              type="text"
              value={holidayHours[day]}
              onChange={(e) => handleHoursChange(day, e.target.value, setHolidayHours)}
              placeholder="Ex: 09:00-12:00, 14:00-19:00"
            />
          </div>
        ))}

        <button type="submit">Enregistrer</button>
      </form>
    </div>
  );
};

export default HoursEntryForm;
