import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './styles/FormStyles.css';
import { departments } from '../data/departments';

const API_URL = 'https://api.sortirleskids.fr/api';

const EditActivityForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [location, setLocation] = useState('');
  const [price, setPrice] = useState('');
  const [openingHours, setOpeningHours] = useState('');
  const [description, setDescription] = useState('');
  const [siret, setSiret] = useState('');
  const [ageMin, setAgeMin] = useState('');
  const [ageMax, setAgeMax] = useState('');
  const [type, setType] = useState('');
  const [subtype, setSubtype] = useState('');
  const [otherSubtype, setOtherSubtype] = useState('');
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [imageUri, setImageUri] = useState(null);
  const [convertedImage, setConvertedImage] = useState(null);
  const [types, setTypes] = useState({});
  const [error, setError] = useState('');

  useEffect(() => {
    fetchActivityDetails();
    fetchTypesAndSubtypes();
  }, []);

  const fetchActivityDetails = async () => {
    try {
      const response = await axios.get(`${API_URL}/activities/${id}`);
      const activity = response.data;
      setName(activity.name);
      setLocation(activity.location);
      setPrice(activity.price);
      setOpeningHours(activity.openingHours);
      setDescription(activity.description);
      setSiret(activity.siret || '');
      setAgeMin(activity.ageMin);
      setAgeMax(activity.ageMax);
      setType(activity.type);
      setSubtype(activity.subtype);
      setSelectedDepartment(activity.departments);
      setImageUri(activity.imageUrl);
    } catch (error) {
      console.error('Erreur lors de la récupération de l\'activité:', error);
    }
  };

  const fetchTypesAndSubtypes = async () => {
    try {
      const response = await axios.get(`${API_URL}/types-subtypes`);
      setTypes(response.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des types et sous-types:', error);
    }
  };

  const getSubtypesForType = (type) => {
    if (!type || !types[type]) {
      return [];
    }
    return types[type].includes('Autre') ? types[type] : [...types[type], 'Autre'];
  };

  const convertToJpg = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (event) {
        const img = new Image();
        img.src = event.target.result;
        img.onload = function () {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0);
          canvas.toBlob(
            (blob) => {
              const jpgFile = new File([blob], `${file.name.split('.')[0]}.jpg`, { type: 'image/jpeg' });
              resolve(jpgFile);
            },
            'image/jpeg',
            0.95
          );
        };
      };
      reader.onerror = function (error) {
        reject(error);
      };
    });
  };

  const handleChooseImage = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageUri(URL.createObjectURL(file));
      if (file.type === 'image/png') {
        const jpgFile = await convertToJpg(file);
        setConvertedImage(jpgFile);
      } else {
        setConvertedImage(file);
      }
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!name || !location || !selectedDepartment || !type || !subtype || !siret || (subtype === 'Autre' && !otherSubtype)) {
      setError('Veuillez remplir tous les champs obligatoires.');
      return;
    }

    try {
      await axios.put(
        `${API_URL}/activities/${id}`,
        { name, location, price, openingHours, description, siret, ageMin, ageMax, type, subtype, departments: selectedDepartment },
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      );

      if (convertedImage) {
        const formData = new FormData();
        formData.append('image', convertedImage);
        formData.append('id', id);
        await axios.post(`${API_URL}/activity-upload`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
      }

      alert('Activité mise à jour avec succès.');
      navigate('/admin/establishment-choice');
    } catch (error) {
      console.error('Erreur lors de la mise à jour de l\'activité:', error);
      setError('Une erreur est survenue.');
    }
  };

  const handleCancel = () => {
    navigate('/admin/modify-activities');
  };

  return (
    <div className="form-container">
      <h2>Modifier une Activité</h2>
      {error && <p className="error-text">{error}</p>}
      <form onSubmit={handleFormSubmit}>
		<label>Numéro SIRET</label>
			<input
			type="text"
			value={siret}
			onChange={(e) => setSiret(e.target.value)}
			placeholder="Entrez le numéro SIRET"
		/>
        <label>Nom de l'Activité</label>
        <input type="text" value={name} onChange={(e) => setName(e.target.value)} required />

        <label>Type d'Activité</label>
        <select value={type} onChange={(e) => setType(e.target.value)} required>
          <option value="" disabled>
            Sélectionner un type
          </option>
          {Object.keys(types).map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </select>
        {type && (
          <>
            <label>Sous-type d'Activité</label>
            <select value={subtype} onChange={(e) => setSubtype(e.target.value)} required>
              <option value="" disabled>
                Sélectionner un sous-type
              </option>
              {getSubtypesForType(type).map((subtype) => (
                <option key={subtype} value={subtype}>
                  {subtype}
                </option>
              ))}
              <option value="Autre">Autre</option>
            </select>
            {subtype === 'Autre' && (
              <input
                type="text"
                value={otherSubtype}
                onChange={(e) => setOtherSubtype(e.target.value)}
                placeholder="Autre sous-type d'activité"
                required
              />
            )}
          </>
        )}

        <label>Localisation</label>
        <input type="text" value={location} onChange={(e) => setLocation(e.target.value)} required />

        <label>Prix</label>
        <input type="text" value={price} onChange={(e) => setPrice(e.target.value)} />

        <label>Âge minimum</label>
        <input type="text" value={ageMin} onChange={(e) => setAgeMin(e.target.value)} />

        <label>Âge maximum</label>
        <input type="text" value={ageMax} onChange={(e) => setAgeMax(e.target.value)} />

        <label>Horaires d'ouverture</label>
        <textarea value={openingHours} onChange={(e) => setOpeningHours(e.target.value)} />

        <label>Description</label>
        <textarea value={description} onChange={(e) => setDescription(e.target.value)} />

        <label>Choisir une image</label>
        <input type="file" id="image" onChange={handleChooseImage} />
        {imageUri && <img src={imageUri} alt="Preview" className="image-preview" />}

        <button type="submit">Mettre à jour</button>
		<button type="button" onClick={handleCancel} className="cancel-button">Annuler</button>
      </form>
    </div>
  );
};

export default EditActivityForm;
