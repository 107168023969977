import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './styles/FormStyles.css';
import { departments } from '../data/departments';

const RestaurantForm = () => {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [location, setLocation] = useState('');
  const [price, setPrice] = useState('');
  const [openingHours, setOpeningHours] = useState('');
  const [description, setDescription] = useState('');
  const [siret, setSiret] = useState('');
  const [type, setType] = useState('');
  const [otherType, setOtherType] = useState('');
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [imageUri, setImageUri] = useState(null);
  const [convertedImage, setConvertedImage] = useState(null); // Pour stocker l'image convertie
  const [restaurantTypes, setRestaurantTypes] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchRestaurantTypes();
  }, []);

  const fetchRestaurantTypes = async () => {
    try {
      const response = await axios.get('https://api.sortirleskids.fr/api/restaurant-types');
      setRestaurantTypes(response.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des types de restaurants:', error);
    }
  };

  const convertToJpg = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (event) {
        const img = new Image();
        img.src = event.target.result;
        img.onload = function () {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0);
          canvas.toBlob(
            (blob) => {
              const jpgFile = new File([blob], `${file.name.split('.')[0]}.jpg`, { type: 'image/jpeg' });
              resolve(jpgFile);
            },
            'image/jpeg',
            0.95
          );
        };
      };
      reader.onerror = function (error) {
        reject(error);
      };
    });
  };

  // Gestionnaire d'image pour la conversion PNG en JPG
  const handleChooseImage = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageUri(URL.createObjectURL(file));
      if (file.type === 'image/png') {
        const jpgFile = await convertToJpg(file);
        setConvertedImage(jpgFile); // Utiliser l'image convertie
      } else {
        setConvertedImage(file);
      }
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!name || !location || !selectedDepartment || !type || !siret || (type === 'Autre' && !otherType)) {
      setError('Veuillez remplir tous les champs obligatoires.');
      return;
    }

    try {
      // Enregistrer les données du restaurant sans l'image d'abord
      const response = await axios.post(
        'https://api.sortirleskids.fr/api/register-restaurant',
        {
          name,
          location,
          departments: selectedDepartment,
          price,
          openingHours,
          description,
          type: type === 'Autre' ? otherType : type,
          siret,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );

      const restaurantId = response.data.id;

      // Gérer l'envoi de l'image si elle est présente
      if (convertedImage) {
        const formData = new FormData();
        formData.append('image', convertedImage); // Utiliser l'image convertie
        formData.append('id', restaurantId);

        await axios.post('https://api.sortirleskids.fr/api/restaurant-upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
      }

      alert('Restaurant enregistré avec succès.');
      navigate('/admin/establishment-choice');
    } catch (error) {
      console.error('Erreur lors de l\'enregistrement du restaurant:', error);
      setError('Une erreur est survenue lors de l\'enregistrement du restaurant.');
    }
  };

  return (
    <div className="form-container">
      <h2>Enregistrer un Restaurant</h2>
      {error && <p className="error-text">{error}</p>}
      <form onSubmit={handleFormSubmit}>
        <label>Numéro SIRET</label>
        <input
          type="text"
          value={siret}
          onChange={(e) => setSiret(e.target.value)}
          placeholder="Numéro SIRET"
          required
        />
        <label>Nom du Restaurant</label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Nom"
          required
        />
        <label>Type de Cuisine</label>
        <select value={type} onChange={(e) => setType(e.target.value)} required>
          <option value="" disabled>
            Sélectionner un type
          </option>
          {restaurantTypes.map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
          <option value="Autre">Autre</option>
        </select>
        {type === 'Autre' && (
          <input
            type="text"
            value={otherType}
            onChange={(e) => setOtherType(e.target.value)}
            placeholder="Autre type de cuisine"
            required
          />
        )}
        <label>Adresse</label>
        <input
          type="text"
          value={location}
          onChange={(e) => setLocation(e.target.value)}
          placeholder="Adresse (ex: 1 rue de la paix, 66000 Perpignan)"
          required
        />
        <label>Département</label>
        <select
          value={selectedDepartment}
          onChange={(e) => setSelectedDepartment(e.target.value)}
          required
        >
          <option value="" disabled>
            Sélectionner un département
          </option>
          {Object.keys(departments).map((dept) => (
            <option key={dept} value={dept}>
              {dept}
            </option>
          ))}
        </select>
        <label>Prix</label>
        <input
          type="text"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          placeholder="Prix"
        />
        <label>Horaires d'ouverture</label>
        <textarea
          value={openingHours}
          onChange={(e) => setOpeningHours(e.target.value)}
          placeholder="Horaires d'ouverture"
        />
        <label>Description</label>
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Description"
        />
        <label>Choisir une image</label>
        <input type="file" id="image" onChange={handleChooseImage} />
        {imageUri && <img src={imageUri} alt="Preview" className="image-preview" />}
        <button type="submit">Enregistrer</button>
      </form>
    </div>
  );
};

export default RestaurantForm;
