import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/Login';
import EstablishmentChoice from './components/EstablishmentChoice';
import ProtectedRoute from './components/ProtectedRoute';
import RestaurantForm from './components/RestaurantForm';
import ActivityForm from './components/ActivityForm';
import FamilyEventForm from './components/FamilyEventForm';
import ModifyActivities from './components/ModifyActivities';
import ModifyRestaurants from './components/ModifyRestaurants';
import ModifyFamilyEvents from './components/ModifyFamilyEvents';
import EditActivityForm from './components/EditActivityForm';
import EditRestaurantForm from './components/EditRestaurantForm';
import EditFamilyEventForm from './components/EditFamilyEventForm';
import HoursJsonForm from './components/HoursJsonForm';

function App() 
{
  	return (
    	<Router>
      		<Routes>
        		<Route path="/admin" element={<Login />} />
        			<Route path="/admin/establishment-choice" element={
            			<ProtectedRoute>
              				<EstablishmentChoice />
            			</ProtectedRoute>
          			}
        			/>
				<Route path="/admin/register-restaurant" element={
          			<ProtectedRoute>
            			<RestaurantForm />
          			</ProtectedRoute>
        		} />
       			<Route path="/admin/register-activity" element={
          			<ProtectedRoute>
            			<ActivityForm />
          			</ProtectedRoute>
        		} />
        		<Route path="/admin/register-family-event" element={
  					<ProtectedRoute>
    					<FamilyEventForm />
  					</ProtectedRoute>
				} />
				<Route path="/admin/modify-activities" element={
		  			<ProtectedRoute>
						<ModifyActivities />
		  			</ProtectedRoute>
				} />
				<Route path="/admin/modify-restaurants" element={
		  			<ProtectedRoute>
						<ModifyRestaurants />
		  			</ProtectedRoute>
				} />
				<Route path="/admin/modify-family-events" element={
		  			<ProtectedRoute>
						<ModifyFamilyEvents />
		  			</ProtectedRoute>
				
				} />
				<Route path="/admin/edit-activity/:id" element={
					<ProtectedRoute>
						<EditActivityForm />
					</ProtectedRoute>
				} />
				<Route path="/admin/edit-restaurant/:id" element={
					<ProtectedRoute>
						<EditRestaurantForm />
					</ProtectedRoute>
				} />
				<Route path="/admin/edit-familyEvent/:id" element={
					<ProtectedRoute>
						<EditFamilyEventForm />
					</ProtectedRoute>
				} />
				<Route path="/admin/HoursManagement" element={
					<ProtectedRoute>
						<HoursJsonForm />
					</ProtectedRoute>
				} />
      		</Routes>
    	</Router>
  	);
}

export default App;