import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './styles/ModifyStyles.css';

const API_URL = 'https://api.sortirleskids.fr/api';

const ModifyActivities = () => {
  const [activities, setActivities] = useState([]);
  const navigate = useNavigate();
  const [error, setError] = useState('');

  useEffect(() => {
    fetchActivities();
  }, []);

  const fetchActivities = async () => {
    try {
      const response = await axios.get('https://api.sortirleskids.fr/api/activities', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setActivities(response.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des activités:', error);
    }
  };

  const handleEditActivity = (id) => {
    navigate(`/admin/edit-activity/${id}`);
  };

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm('Êtes-vous sûr de vouloir supprimer cette activité ?');
    if (confirmDelete) {
      try {
        await axios.delete(`${API_URL}/activities/${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        setActivities(activities.filter((activity) => activity.id !== id));
        alert('Activité supprimée avec succès.');
      } catch (error) {
        setError('Erreur lors de la suppression de l\'activité.');
      }
    }
  };

  return (
    <div>
	  <button className="home-button" onClick={() => navigate('/admin/establishment-choice')}>
        Revenir à l'accueil
      </button>
      <h2>Modifier une Activité</h2>
	  {error && <p className="error-message">{error}</p>}
      <ul>
        {activities.map((activity) => (
          <li key={activity.id} className="modify-item">
			<div className="activity-details">
              <strong>ID : {activity.id} </strong>
              <span className="activity-name">{activity.name}</span> - <span>{activity.location}</span>
            </div>
			<div className="modify-buttons">
            	<button onClick={() => handleEditActivity(activity.id)}>Modifier</button>
				<button onClick={() => handleDelete(activity.id)} className="delete-button">Supprimer</button>
			</div>
		  </li>
        ))}
      </ul>
    </div>
  );
};

export default ModifyActivities;
