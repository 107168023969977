import React from 'react';
import { useNavigate } from 'react-router-dom';
import './styles/EstablishmentChoice.css';
import logo from '../img/logo2.png'; 

const EstablishmentChoice = () => {
  const navigate = useNavigate();

  const handleChoice = (choice) => {
    switch (choice) {
      case 'restaurant':
        navigate('/admin/register-restaurant');
        break;
      case 'activity':
        navigate('/admin/register-activity');
        break;
      case 'family-event':
        navigate('/admin/register-family-event');
        break;
      case 'modify-restaurant':
        navigate('/admin/modify-restaurants');
        break;
      case 'modify-activity':
        navigate('/admin/modify-activities');
        break;
      case 'modify-family-event':
        navigate('/admin/modify-family-events');
        break;
	  case 'schedule-management':
		navigate('/admin/HoursManagement');
		break;
      default:
        break;
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/admin');
  };

  return (
    <div className="choice-container">
      <img src={logo} alt="Logo" className="logo" />
      <h2 className="title">Quel type d'établissement souhaitez-vous ajouter ou modifier ?</h2>
      <button className="choice-button" onClick={() => handleChoice('restaurant')}>
        Ajouter un Restaurant
      </button>
      <button className="choice-button" onClick={() => handleChoice('activity')}>
        Ajouter une Activité
      </button>
      <button className="choice-button" onClick={() => handleChoice('family-event')}>
        Ajouter un Événement Famille
      </button>
      <button className="choice-button" onClick={() => handleChoice('modify-restaurant')}>
        Modifier un Restaurant
      </button>
      <button className="choice-button" onClick={() => handleChoice('modify-activity')}>
        Modifier une Activité
      </button>
      <button className="choice-button" onClick={() => handleChoice('modify-family-event')}>
        Modifier un Événement Famille
      </button>
	  <button className="choice-button" onClick={() => handleChoice('schedule-management')}>
		Gestion des Horaires - JSON
	  </button>
      <button className="logout-button" onClick={handleLogout}>
        Déconnexion
      </button>
    </div>
  );
};

export default EstablishmentChoice;
