import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './styles/FormStyles.css';
import { departments } from '../data/departments';

const API_URL = 'https://api.sortirleskids.fr/api';

const EditFamilyEventForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [location, setLocation] = useState('');
  const [type, setType] = useState('');
  const [description, setDescription] = useState('');
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [imageUri, setImageUri] = useState(null);
  const [convertedImage, setConvertedImage] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchFamilyEventDetails();
  }, []);

  const fetchFamilyEventDetails = async () => {
    try {
      const response = await axios.get(`${API_URL}/family-events/${id}`);
      const event = response.data;
      setName(event.name);
      setStartDate(event.startDate);
      setEndDate(event.endDate);
      setLocation(event.location);
      setType(event.type);
      setDescription(event.description);
      setSelectedDepartment(event.departments);
      setImageUri(event.imageUrl); // Image actuelle
    } catch (error) {
      console.error('Erreur lors de la récupération de l\'événement familial:', error);
    }
  };

  const convertToJpg = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (event) {
        const img = new Image();
        img.src = event.target.result;
        img.onload = function () {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0);
          canvas.toBlob(
            (blob) => {
              const jpgFile = new File([blob], `${file.name.split('.')[0]}.jpg`, { type: 'image/jpeg' });
              resolve(jpgFile);
            },
            'image/jpeg',
            0.95
          );
        };
      };
      reader.onerror = function (error) {
        reject(error);
      };
    });
  };

  const handleChooseImage = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageUri(URL.createObjectURL(file));
      if (file.type === 'image/png') {
        const jpgFile = await convertToJpg(file);
        setConvertedImage(jpgFile);
      } else {
        setConvertedImage(file);
      }
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!name || !startDate || !endDate || !location || !selectedDepartment || !type) {
      setError('Veuillez remplir tous les champs obligatoires.');
      return;
    }

    try {
      await axios.put(
        `${API_URL}/family-events/${id}`,
        { name, startDate, endDate, location, departments: selectedDepartment, type, description },
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      );

      if (convertedImage) {
        const formData = new FormData();
        formData.append('image', convertedImage); // Utiliser l'image convertie
        formData.append('id', id);

        await axios.post(`${API_URL}/family-event-upload`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
      }

      alert('Événement familial mis à jour avec succès.');
      navigate('/admin/establishment-choice');
    } catch (error) {
      console.error('Erreur lors de la mise à jour de l\'événement familial:', error);
      setError('Une erreur est survenue.');
    }
  };

  const handleCancel = () => {
    navigate('/admin/modify-family-events');
  };

  return (
    <div className="form-container">
      <h2>Modifier un Événement Famille</h2>
      {error && <p className="error-text">{error}</p>}
	  <form onSubmit={handleFormSubmit}>
		<label>Nom de l'Événement</label>
		<input type="text" value={name} onChange={(e) => setName(e.target.value)} required />

		<label>Date de Début</label>
		<input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} required />

		<label>Date de Fin</label>
		<input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} required />

		<label>Localisation</label>
		<input type="text" value={location} onChange={(e) => setLocation(e.target.value)} required />

		<label>Type d'Événement</label>
		<input type="text" value={type} onChange={(e) => setType(e.target.value)} required />

		<label>Description</label>
		<textarea value={description} onChange={(e) => setDescription(e.target.value)} />

		<label>Département</label>
		<select value={selectedDepartment} onChange={(e) => setSelectedDepartment(e.target.value)} required>
			<option value="">Sélectionner un département</option>
			{Object.keys(departments).map((dept) => (
			<option key={dept} value={dept}>
				{dept}
			</option>
			))}
		</select>

        <label>Choisir une image</label>
        <input type="file" id="image" onChange={handleChooseImage} />
        {imageUri && <img src={imageUri} alt="Preview" className="image-preview" />}

		<button type="submit">Mettre à jour</button>
		<button type="button" onClick={handleCancel} className="cancel-button">Annuler</button>
	  </form>
    </div>
  );
};

export default EditFamilyEventForm;
