import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './styles/ModifyStyles.css';

const API_URL = 'https://api.sortirleskids.fr/api';

const ModifyFamilyEvents = () => {
  const [familyEvents, setFamilyEvents] = useState([]);
  const navigate = useNavigate();
  const [error, setError] = useState('');

  useEffect(() => {
    fetchFamilyEvents();
  }, []);

  const fetchFamilyEvents = async () => {
    try {
      const response = await axios.get(`${API_URL}/family-events`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setFamilyEvents(response.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des événements famille:', error);
      setError('Erreur lors de la récupération des événements famille.');
    }
  };

  const handleEditFamilyEvent = (id) => {
    navigate(`/admin/edit-family-event/${id}`);
  };

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm('Êtes-vous sûr de vouloir supprimer cet événement famille ?');
    if (confirmDelete) {
      try {
        await axios.delete(`${API_URL}/family-events/${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        setFamilyEvents(familyEvents.filter((event) => event.id !== id));
        alert('Événement famille supprimé avec succès.');
      } catch (error) {
        setError('Erreur lors de la suppression de l\'événement famille.');
      }
    }
  };

  return (
    <div className="modify-container">
	  <button className="home-button" onClick={() => navigate('/admin/establishment-choice')}>
        Revenir à l'accueil
      </button>
      <h2>Modifier un Événement Famille</h2>
      {error && <p className="error-text">{error}</p>}
      <ul className="modify-list">
        {familyEvents.map((event) => (
          <li key={event.id} className="modify-item">
            <div className="event-details">
              <strong>ID : {event.id}</strong>
              <span className="event-name">{event.name}</span> - <span>{event.location}</span>
            </div>
            <div className="modify-buttons">
              <button onClick={() => handleEditFamilyEvent(event.id)} className="edit-button">Modifier</button>
              <button onClick={() => handleDelete(event.id)} className="delete-button">Supprimer</button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ModifyFamilyEvents;