import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './styles/FormStyles.css';
import { departments } from '../data/departments';

const API_URL = 'https://api.sortirleskids.fr/api';

const FamilyEventForm = () => 
{
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [location, setLocation] = useState('');
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [type, setType] = useState('');
  const [description, setDescription] = useState('');
  const [imageUri, setImageUri] = useState(null);
  const [convertedImage, setConvertedImage] = useState(null);
  const [error, setError] = useState('');

  const convertToJpg = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (event) {
        const img = new Image();
        img.src = event.target.result;
        img.onload = function () {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0);
          canvas.toBlob(
            (blob) => {
              const jpgFile = new File([blob], `${file.name.split('.')[0]}.jpg`, { type: 'image/jpeg' });
              resolve(jpgFile);
            },
            'image/jpeg',
            0.95
          );
        };
      };
      reader.onerror = function (error) {
        reject(error);
      };
    });
  };

  const handleChooseImage = async (e) => 
  {
    const file = e.target.files[0];
    if (file) 
	{
      setImageUri(URL.createObjectURL(file));
      if (file.type === 'image/png') 
	  {
        const jpgFile = await convertToJpg(file);
        setConvertedImage(jpgFile);
      } 
	  else 
	  {
        setConvertedImage(file);
      }
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!name || !startDate || !endDate || !location || !selectedDepartment || !type) 
	{
      setError('Veuillez remplir tous les champs obligatoires.');
      return;
    }

    try 
	{
      const formData = new FormData();
      formData.append('name', name);
      formData.append('startDate', startDate);
      formData.append('endDate', endDate);
      formData.append('location', location);
      formData.append('departments', selectedDepartment);
      formData.append('type', type);
      formData.append('description', description);

      if (convertedImage) 
	  {
        formData.append('image', convertedImage);
      }

      const response = await axios.post(`${API_URL}/family-events`, formData, {
        headers: 
		{
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      alert('Événement familial enregistré avec succès.');
      navigate('/admin/establishment-choice');
    } 
	catch (error) 
	{
      console.error('Erreur lors de l\'enregistrement de l\'événement famille:', error);
      setError('Une erreur est survenue lors de l\'enregistrement de l\'événement.');
    }
};

  return (
    <div className="form-container">
      <h2>Enregistrer un Événement Famille</h2>
      {error && <p className="error-text">{error}</p>}
      <form onSubmit={handleFormSubmit}>
        <label>Nom de l'Événement</label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Nom de l'événement"
          required
        />

        <label>Date de début</label>
        <input
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          required
        />

        <label>Date de fin</label>
        <input
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          required
        />

        <label>Localisation</label>
        <input
          type="text"
          value={location}
          onChange={(e) => setLocation(e.target.value)}
          placeholder="Adresse"
          required
        />

        <label>Départements concernés</label>
        <select
          value={selectedDepartment}
          onChange={(e) => setSelectedDepartment(e.target.value)}
          required
        >
          <option value="" disabled>
            Sélectionner un département
          </option>
          {Object.keys(departments).map((dept) => (
            <option key={dept} value={dept}>
              {dept}
            </option>
          ))}
        </select>

        <label>Type d'Événement</label>
        <input
          type="text"
          value={type}
          onChange={(e) => setType(e.target.value)}
          placeholder="Type d'événement"
          required
        />

        <label>Description</label>
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Description"
        />

        <label>Choisir une image</label>
        <input type="file" id="image" accept="image/*" onChange={handleChooseImage} />
        {imageUri && <img src={imageUri} alt="Preview" className="image-preview" />}

        <button type="submit">Enregistrer</button>
      </form>
    </div>
  );
};

export default FamilyEventForm;
